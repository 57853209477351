import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/components/ButtonCheckout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/components/ErrorBoundary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/components/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/components/Testimonials11.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/components/VideoCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/components/YoutubeVideoPlayer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["Image"] */ "/opt/buildhome/repo/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/public/icon.png");
