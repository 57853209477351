'use client';

import React, { useEffect, useRef, useCallback } from 'react';

const videoUrls = [
  'https://video-cdn.autoshorts.ai/demos/xrjyu373iy.mp4',
  'https://video-cdn.autoshorts.ai/demos/f007dsau4wk.mp4',
  'https://video-cdn.autoshorts.ai/demos/n7pk1hz19z.mp4',
  'https://video-cdn.autoshorts.ai/demos/4l8g299olsk.mp4',
  'https://video-cdn.autoshorts.ai/demos/lqljgm9xljl.mp4',
  'https://video-cdn.autoshorts.ai/demos/lqkzompfhq8.mp4',
  'https://video-cdn.autoshorts.ai/demos/y6ccmo66yp.mp4',
  'https://video-cdn.autoshorts.ai/demos/scary.mp4',
  'https://video-cdn.autoshorts.ai/demos/motivational.mp4',
  // 重复这些URL以匹配您示例中的20个视频
];

const VideoCarousel: React.FC = () => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const videoRefs = useRef<HTMLVideoElement[]>([]);

  // 确保所有视频保持静音
  const muteAllVideos = useCallback(() => {
    videoRefs.current.forEach((video, index) => {
      if (video) {
        video.muted = true;
        video.volume = 0;
        console.log(`Video ${index} muted:`, video.muted);
        video.play().catch(error => console.error('自动播放失败:', error));
      }
    });
  }, []);

  useEffect(() => {
    // 初始挂载时静音所有视频
    muteAllVideos();

    // 监听页面显示事件（包括从其他页面返回）
    const handlePageShow = () => {
      console.log('pageshow event triggered');
      muteAllVideos();
    };

    // 监听页面获得焦点事件
    const handleFocus = () => {
      console.log('focus event triggered');
      muteAllVideos();
    };

    // 监听页面可见性变化事件
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        console.log('visibilitychange event triggered');
        muteAllVideos();
      }
    };

    window.addEventListener('pageshow', handlePageShow);
    window.addEventListener('focus', handleFocus);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      window.removeEventListener('pageshow', handlePageShow);
      window.removeEventListener('focus', handleFocus);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [muteAllVideos]);

  // 自动滚动
  useEffect(() => {
    const updateScrollPosition = () => {
      if (carouselRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
        const maxScroll = scrollWidth - clientWidth;
        if (scrollLeft >= maxScroll) {
          carouselRef.current.scrollLeft = 0;
        } else {
          carouselRef.current.scrollLeft += 1;
        }
      }
    };

    const animationInterval = setInterval(updateScrollPosition, 20);
    return () => clearInterval(animationInterval);
  }, []);

  return (
    <section className="relative py-16 md:py-24 bg-gradient-to-b from-gray-50 to-white overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12 md:mb-16">
          <h2 className="text-3xl md:text-4xl lg:text-5xl font-extrabold text-gray-900 mb-4">
            Viral Faceless Videos with AI
          </h2>
          <p className="text-lg md:text-xl text-gray-600 max-w-2xl mx-auto">
            Create captivating content in minutes, choose your theme, and go viral
          </p>
        </div>
        
        <div className="relative">
          <div className="absolute inset-0 bg-gradient-to-r from-white via-transparent to-white z-10 pointer-events-none" />
          <div 
            ref={carouselRef}
            className="flex overflow-x-hidden"
            style={{ scrollBehavior: 'smooth' }}
          >
            {videoUrls.concat(videoUrls).map((url, index) => (
              <div key={index} className="flex-none w-40 sm:w-48 md:w-56 p-2">
                <div className="bg-white rounded-xl shadow-lg overflow-hidden transform transition-all duration-300 hover:scale-105">
                  <div className="aspect-[9/16] relative">
                    <video
                      ref={el => {
                        if (el) {
                          videoRefs.current[index] = el;
                          // 确保每个视频在引用时被静音
                          el.muted = true;
                          el.volume = 0;
                          console.log(`Video ${index} initial muted:`, el.muted);
                        }
                      }}
                      src={url}
                      className="absolute inset-0 w-full h-full object-cover"
                      playsInline
                      autoPlay
                      loop
                      muted
                      onLoadedMetadata={() => {
                        muteAllVideos();
                      }}
                      onCanPlay={() => {
                        muteAllVideos();
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default VideoCarousel;
