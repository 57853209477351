"use client";

import { useState } from "react";
import apiClient from "@/libs/api";
import { createClientComponentClient } from "@supabase/auth-helpers-nextjs";
import { useRouter } from 'next/navigation';
import { useTranslations } from 'next-intl';

// This component is used to create Lemon Squeezy Checkout Sessions
// It calls the /api/lemonsqueezy/create-checkout route with the variantId and redirectUrl
const ButtonCheckout = ({ variantId }: { variantId: string }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const router = useRouter();
  const t = useTranslations('Pricing');

  const handlePayment = async () => {
    setIsLoading(true);

    const supabase = createClientComponentClient();
    const { data: { user } } = await supabase.auth.getUser();

    if (!user) {
      router.push('/signin');
      return;
    }

    if (variantId === '0') {
      router.push('/submit');
      return;
    }

    try {
      const { url }: { url: string } = await apiClient.post(
        "/lemonsqueezy/create-checkout",
        {
          variantId,
          redirectUrl: window.location.href,
        }
      );

      // 直接重定向到结账页面，而不是尝试打开新窗口
      window.location.href = url;
    } catch (e) {
      console.error("结账过程中出错:", e);
      // 可以在这里添加错误处理，比如显示一个错误消息给用户
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <button
      className={`btn btn-primary btn-block h-12 relative ${
        isLoading ? 'bg-opacity-70 hover:bg-opacity-70' : ''
      }`}
      onClick={handlePayment}
      disabled={isLoading}
    >
      {isLoading && (
        <span className="absolute left-4 top-1/2 transform -translate-y-1/2">
          <span className="loading loading-spinner loading-xs"></span>
        </span>
      )}
      <span className={isLoading ? "text-primary-content" : ""}>
        {t('Free Trial')}
      </span>
    </button>
  );
};

export default ButtonCheckout;