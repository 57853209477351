"use client";

import { useState } from "react";
import Image from "next/image";

interface VideoPlayerProps {
  videoId: string;
}

const VideoPlayer = ({ videoId }: VideoPlayerProps) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayVideo = () => {
    setIsPlaying(true);
  };

  return (
    <>
      {!isPlaying ? (
        <div className="relative cursor-pointer" onClick={handlePlayVideo}>
          <Image
            src={`https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`}
            alt="AI Video Creation Demo"
            className="w-full rounded-2xl shadow-2xl hover:shadow-3xl transition-all duration-300"
            priority={true}
            width={600}
            height={338}
          />
          <div className="absolute inset-0 flex items-center justify-center">
            <svg className="w-20 h-20 text-primary opacity-80 hover:opacity-100 transition-opacity" fill="currentColor" viewBox="0 0 24 24">
              <path d="M8 5v14l11-7z" />
            </svg>
          </div>
        </div>
      ) : (
        <iframe
          className="w-full aspect-video rounded-2xl shadow-2xl"
          src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
          title="AI Video Creation Demo"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      )}
    </>
  );
};

export default VideoPlayer;
